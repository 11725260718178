:root {
  --base-color-h              : 0;
  --base-color-s              : 0%;
  --base-color-l              : 100%;
  --primary-color             : hsl(var(--base-color-h), var(--base-color-s), var(--base-color-l));
  --primary-color-light       : hsl(var(--base-color-h), var(--base-color-s), calc(var(--base-color-l) + 40%));
  --primary-color-super-light : hsl(var(--base-color-h), var(--base-color-s), calc(var(--base-color-l) + 50%));
  --primary-color-dark        : hsl(var(--base-color-h), var(--base-color-s), calc(var(--base-color-l) - 8%));
  --primary-color-super-dark  : hsl(var(--base-color-h), var(--base-color-s), calc(var(--base-color-l) - 16%));
}

/* Colors */
$neutral-color             : #F4F3F1;
$neutral-color-20          : #F9F9F8;
$dark-color                : #425669;
$text-color                : #132C43;
$primary-color             : var(--primary-color);
$primary-color-light       : var(--primary-color-light);
$primary-color-super-light : var(--primary-color-super-light);
$primary-color-dark        : var(--primary-color-dark);
$primary-color-super-dark  : var(--primary-color-super-dark);
$secondary-color           : $primary-color;
$gray-text-color           : #A3A3A3;
$input-border-color        : #ced4da;

$neutral50                 : #ECEBE8;
$neutral60                 : #BDBCBA;
$neutral70                 : #8E8D8B;

$light-green               : #91F3C8;
$green                     : #81c332;
$light-yellow              : #FFB40F;
$yellow                    : #FF9C2A;
$light-red                 : #FF4B4B;
$red                       : #D13200;
$neutral-blue              : #4D78D5FF;

$success                   : $green;
$success-background        : #F0F8E6;
$warning                   : #DE8600;

/* Viewports (max widths) */
$viewport-xs-min           : 0;
$viewport-xs-max           : 575px;
$viewport-sm-min           : ($viewport-xs-max + 1);
$viewport-sm-max           : 767px;
$viewport-md-min           : ($viewport-sm-max + 1);
$viewport-md-max           : 991px;
$viewport-lg-min           : ($viewport-md-max + 1);
$viewport-lg-max           : 1199px;
$viewport-xl-min           : ($viewport-lg-max + 1);

$footer-height             : 54px;

/* Fonts */
$basic-font                : 'Catamaran', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$header-font               : 'Catamaran', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;
$subheader-font            : 'Catamaran', 'Helvetica Neue', Arial, 'Noto Sans', sans-serif;

$big-letter-spacing        : 0.04rem;

$header-height-default     : 54px;
$header-height-md          : 64px;
$header-height-xl          : 88px;
$logo-to-header-ratio      : 0.75;

.icon-primary-light {
  color  : $primary-color-light;
  cursor : pointer;
}

.icon-primary-light:hover {
  color  : $primary-color;
  cursor : pointer;
}

.icon-secondary {
  color  : $gray-text-color;
  cursor : pointer;

  &:hover {
    color : #bbbbbb;
  }
}

.icon-primary {
  color  : $primary-color-dark;
  cursor : pointer;

  &:hover {
    color : $primary-color;
  }
}

.icon-primary-not-clickable {
  color : $primary-color;
}

.icon-success-not-clickable {
  color : $success;
}

.icon-error-not-clickable {
  color : $red;
}

.icon-label {
  cursor : pointer;
}

.icon-center-right {
  position : absolute;
  right    : 2rem;
  z-index  : 10;
  top      : 0;
  bottom   : 0;
  margin   : auto;
}

.icon-size-md {
  font-size : 1.25rem;
}

.icon-neutral {
  color : $neutral70;
  cursor: pointer;

  &:hover {
    color: $neutral50;
  }
}

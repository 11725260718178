//#image-cropper-dialog {
//  .modal-dialog {
//    width: 50%;
//  }
//}

.img-container {
  height : 64px;
  width  : 64px;

  img {
    max-height : 100%;
    max-width  : 100%;
  }
}

.image-cropper {
  div {
    img {
      display : inline;
      width   : 100%;
    }
  }
}

.account-customer-number {
  margin : 0 0 -64px 0;
}

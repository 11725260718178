@import 'variables';

html {
  position   : relative;
  min-height : 100%;
  overflow   : auto !important;
}

body {
  min-height  : 100%;
  color       : $text-color;
  font-weight : 400;
  font-family : $basic-font;
}

input[type=text] {
  border-radius : 3px;
}

#root {
  height        : 100%;
  margin-bottom : $footer-height;
}


a {
  color : $primary-color;

  &:hover, &:focus, &:active {
    color : $primary-color-dark;
  }
}

.inactive, .inactive:hover {
  text-decoration : line-through;
}

@media only screen and (min-width : 1440px) {
  .container {
    max-width : 1440px;
  }
}

.container {
  padding-bottom : 5rem;
  @media (min-width : $viewport-lg-min) {
    padding-bottom : 6rem;

    .center-screen {
      max-width: 65%;
      margin: auto;
    }
  }
  @media (max-width : $viewport-sm-max) {
    padding-top : 5rem;
  }

  @media (min-width : $viewport-md-min) {
    padding-top : 10rem;
  }
}

.container-condensed {
  padding-top: 4rem;

  @media (min-width : $viewport-md-min) {
    padding-top : 5rem;
  }

  @media (min-width : $viewport-lg-min) {
    padding-top : 5.5rem;
  }

  @media (min-width : $viewport-xl-min) {
    padding-top : 7rem;
  }
}

/* Chrome, Safari, Edge, Opera - Remove arrows on number input fields*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance : none;
  margin             : 0;
}

/* Firefox - Remove arrows on number input fields */
input[type=number] {
  -moz-appearance : textfield;
}

p {
  line-height : 1.8rem;
}

.is-invalid .dropdown__control {
  border-color : $red !important;
}

.clickable {
  cursor : pointer;
}

.ordered-list {
  padding       : 0.3rem 0 0 60px;
  list-style    : none;
  counter-reset : steps-counter;

  li {
    counter-increment : steps-counter;
    display           : block;
    padding-top       : 5px;
    margin            : 0 0 2rem;
    color             : $text-color;
    position          : relative;

    &:before {
      content       : counter(steps-counter) '.';
      font-weight   : bold;
      border        : 2px solid $secondary-color;
      color         : $secondary-color;
      background    : #fff;
      border-radius : 50%;
      width         : 40px;
      height        : 40px;
      line-height   : 40px;
      display       : inline-block;
      margin-right  : 1rem;
      text-align    : center;
      position      : absolute;
      top           : -0.3rem;
      left          : -60px;
      padding-left  : 0.25rem;
    }

    &:last-child {
      margin-bottom : 0;
    }
  }
}

.box {
  border       : 2px solid $primary-color;
  padding      : 1.7rem 0.7rem 1rem;
  margin-right : 0px;
  margin-left  : 0px;
}

/**
 * Positions
 */
.position-top-right {
  top   : 0;
  right : 0;
}

.pre-wrap {
  white-space : pre-wrap;
}

.tile {
  margin-top       : 0.5rem;
  padding          : 0.75rem;
  background-color : $neutral-color-20;
}

@mixin shadows($border-color: #ECEBE8) {
  border     : 1px solid $border-color;
  box-sizing : border-box;
  box-shadow : 0px 4px 26px 0px #cccccc40;
}

.notification {
  padding-top    : 1.3rem;
  padding-bottom : 1rem;
  padding-left   : 1.5rem;
  padding-right  : 1.5rem;
  margin-bottom  : 2rem;
  color          : $success;
  background     : $primary-color-super-light;
}

@import '../../styles/variables';

.language-selector {
  padding         : 0;
  list-style-type : none;
  margin          : 0;

  li {
    display : inline-block;
    color   : $text-color;

    &:after {
      color   : $text-color;
      content : '|';
      display : inline;
      margin  : 10px;
    }

    &:last-child:after {
      display : none;
    }

    a {
      color  : $text-color;
      margin : 0
    }
  }
}
